import React from 'react'
import { css } from '@emotion/core'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'

const styles = {
  container: css`
    ${breakPoints({
      paddingTop: [40, 40, 90, 110, 110],
      paddingBottom: [40, 40, 90, 110, 110],
    })};
  `,
  section: css`
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    ul {
      padding-left: 30px;
      list-style-type: circle;
    }
  `,
}
function Privacy() {
  return (
    <div css={styles.container}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 offset-md-0'>
            <section css={styles.section}>
              <Title
                size='xxxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Privacy
              </Title>
              <Text size='medium' color={colors.charcoal}>
                Daily Quest Plus knows that you care how information
                about you is used and shared, and we’re not in the
                business of selling customer information. You can find
                out more about how your personal information is
                protected below:
              </Text>
            </section>

            <section css={styles.section}>
              <Text size='medium' color={colors.charcoal}>
                The information we gather from customers helps us
                personalise and continually improve your experience.
                Here are the types of information we gather: We
                receive and store any information you enter on our Web
                site or give us in any other way. You can choose not
                to provide certain information, but then you might not
                be able to take advantage of many of our features. We
                use the information that you provide for such purposes
                as responding to your requests, customising future
                shopping for you, improving our stores, and
                communicating with you. We receive and store certain
                types of information whenever you interact with us.
              </Text>
            </section>

            <section css={styles.section}>
              <Text size='medium' color={colors.charcoal}>
                Information about our customers is an important part
                of our business, and we are not in the business of
                selling it to others. We work to protect the security
                of your information during transmission by using
                Secure Sockets Layer (SSL) software, which encrypts
                information you input. We transmit the entire credit
                card number to the appropriate credit card company
                during order processing and it is fully encrypted. We
                do not store your payment information. It is important
                for you to protect against unauthorised access to your
                password and to your computer. Be sure to sign off
                when finished using a shared computer.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Conditions of Use, Notices, and Revisions
              </Title>
              <Text size='medium' color={colors.charcoal}>
                If you choose to visit our website , your visit and
                any dispute over privacy is subject to this Notice and
                our conditions of use, including limitations on
                damages, resolution of disputes, and application of
                the law. If you have any concern about privacy at,
                please contact us with a thorough description, and we
                will try to resolve it. Unless stated otherwise, our
                current Privacy Notice applies to all information that
                we have about you and your account. We stand behind
                the promises we make, however, and will never
                materially change our policies and practices to make
                them less protective of customer information collected
                in the past without the consent of affected customers.
              </Text>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
